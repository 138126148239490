import StakingImg from "../../assets/pika_staking.png";
import EvolutionImg from "../../assets/pika_evolution.png";
import LikImg from "../../assets/pika_lp.png";
import PikaImg from "../../assets/pika_mascot_image.jpg";
import NftImg from "../../assets/nft.jpg";
import { Link } from "react-router-dom";
import { Container } from "./styles";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
export function SectionImg() {
  return (
    <Container>
      {/* <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner1} alt="First slide" />
        </Carousel.Item>
      </Carousel> */}

      <div className="card-section">
        {/* <div>
          <p className="cards-title">START PLAYING</p>
        </div> */}
        <div
          // className="options-row row-data"
          className="grid"
        >
          <Col className="">
            <Card>
              <Link
                to="/staking"
                style={{
                  color: "white",
                  outline: "none",
                  textDecoration: "none",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                <div className="linkData">
                  <Card.Img
                    variant="top"
                    src={StakingImg}
                    style={{ width: "100%" }}
                  />
                </div>
                <Card.Body>
                  <Card.Title>Stake Your Tokens</Card.Title>
                </Card.Body>
              </Link>
            </Card>
          </Col>
          {/* <Col>
            <Card>
              <Link to="/evolution">
                <div className="linkData">
                  <Card.Img variant="top" src={EvolutionImg} />
                </div>
                <Card.Body>
                  <Card.Title>Evolution</Card.Title>
                </Card.Body>
              </Link>
            </Card>
          </Col> */}
          <Col>
            <Card>
              <Link to="/liquidity">
                <div className="linkData">
                  <Card.Img variant="top" src={LikImg} />
                </div>
                <Card.Body>
                  <Card.Title>Liquidity Provision</Card.Title>
                </Card.Body>
              </Link>
            </Card>
          </Col>
          <Col>
            <Card>
              <Link>
                <div className="linkData">
                  <Card.Img
                    variant="top"
                    src={PikaImg}
                    style={{ width: "110%", borderRadius: "10px" }}
                  />
                </div>
                <Card.Body>
                  <Card.Title>Collect Rewards</Card.Title>
                </Card.Body>
              </Link>
            </Card>
          </Col>
        </div>
      </div>
    </Container>
  );
}
