import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;

  iframe {
    width: 900px;
    height: 500px;
  }

  @media (max-width: 1000px) {
    iframe {
      width: 90vw !important;
      height: 400px !important;
    }
  }
`;
