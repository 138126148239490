import backgroundImg from "../../assets/universalbg.jpg";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { MetraEvolutionControls } from "../../components/MetraEvolutionControls";
import { Container } from "./styles";

export function MetraEvl() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <MetraEvolutionControls />
      <Footer />
    </Container>
  );
}
