import styled from "styled-components";

export const ContainerCustom = styled.div`
  .navbar{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0
  }
  .navbar-dark {
    background-color: transparent !important;
  }
  .header-custom {
    margin-left: auto !important;
    margin-right: 0 !important;
  }
  .nav-link {
    color: #fff !important;
    size: 16px;
  }
  .dropdown-menu {
    background-color: #111111;
    box-shadow: 0 0.25rem 0.9375rem 0 rgb(0 0 0 / 20%);
    color: #fff;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .btn-connect {
    background-color: purple;
    border-color: purple;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 15px;
    max-width: 90vw
  }
  .btn-danger {
    background-color: blue;
    border-color: blue;
    border-radius: 20px;
    margin-right: 10px;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    .btn-connect-div {
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      justify-content: center;
      margin: auto;
      flex-direction: column;
      gap: 10px;
    }
  }
  
  @media (min-width: 769px) {
    .btn-connect-div {
      display: flex;
      margin-left: auto !important;
      margin-right: 0 !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
      padding-bottom: 10px;
    }
  }
  
  .price-text-div{
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    padding: 10px;
  }
  
  .price-text {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    font-size: 0.8em
    font-family: Arial;
  }

  @media (max-width: 1279px) {
    .btn-connect-div {
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  @media (min-width: 1280px) {
    .btn-connect-div {
      display: flex;
      margin-left: auto !important;
      margin-right: 0 !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
      padding-bottom: 10px;
    }
  }

`;
