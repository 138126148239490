import backgroundImg from "../../assets/universalbg.jpg";
import { EvolutionSelection } from "../../components/EvolutionSelection";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Container } from "./styles";

export function Evolution() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <EvolutionSelection />
      <Footer />
    </Container>
  );
}
