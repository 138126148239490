import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { Context, WebConnect } from "../../context";

export default function WalletConnButton() {
  const context = useContext(Context);
  const [address, setAddress] = React.useState("");
  React.useEffect(() => {
    if (context.provider === null) {
      setAddress("");
    }
    console.log("provider", context.provider);
    const signer = context.provider?.getSigner();
    signer?.getAddress().then((address) => setAddress(address));
  }, [context]);
  return (
    <>
      {address?.length >= 1 ? (
        <>
          <Button
            id="btn-connect"
            className="btn-connect text-truncate"
            size="sm"
          >
            {address}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              context.setProvider(null);
            }}
          >
            Disconnect
          </Button>
        </>
      ) : (
        <Button
          id="btn-connect"
          onClick={async () => {
            context.setProvider(await WebConnect());
          }}
          className="btn-connect"
          size="sm"
        >
          Connect Wallet
        </Button>
      )}
    </>
  );
}
