import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { Link } from "react-router-dom";
import StakingImg from "../../assets/pika_to_metra.png";
import StakingImg2 from "../../assets/metra_to_kai.png";
import { Container } from "./styles";

export function EvolutionSelection() {
  return (
    <>
      <Container>
        <div className="content">
          <div className="">
            <div className="staking-title title-stake">
              <Link to="/">
                <Button variant="outline-light">Go Back</Button>
              </Link>
              <p className="page-title">Select Evolution</p>
            </div>

            <CardGroup style={{ justifyContent: "center", gap: 80 }}>
              <Card
                style={{
                  maxWidth: 220,
                  minWidth: 220,
                  maxHeight: 220,
                  minHeight: 220,
                  margin: +window.innerWidth <= 575 ? "20px auto 90px" : "0",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/evolution/metra"
                  style={{
                    color: "white",
                    outline: "none",
                    textDecoration: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <Card.Img variant="top" src={StakingImg} />
                  <Card.Body
                    style={{
                      position: "absolute",
                      bottom: -65,
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Card.Title>PIKA TO METRA</Card.Title>
                    <Card.Footer>
                      <span>Time to evolve</span>
                      <span>24 hours</span>
                    </Card.Footer>
                  </Card.Body>
                </a>
              </Card>

              <Card
                style={{
                  maxWidth: 220,
                  minWidth: 220,
                  maxHeight: 220,
                  minHeight: 220,
                  margin: +window.innerWidth <= 575 ? "20px auto 90px" : "0",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card.Img variant="top" src={StakingImg2} />
                <Card.Body
                  style={{
                    position: "absolute",
                    bottom: -100,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Card.Title>METRA TO KAI</Card.Title>
                  <Card.Title>Under development</Card.Title>
                  <Card.Footer>
                    <span>Time to evolve</span>
                    <span>24 hours</span>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </CardGroup>
          </div>
        </div>
      </Container>
    </>
  );
}
