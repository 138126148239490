import { Background } from "../../components/Background";
import { Footer } from "../../components/Footer";
import HowToStake from "../../components/HowToStake";
import "../../styles/App.css";
import "../../styles/vendors.css";

function Home() {
  return (
    <>
      <div className="barba-container">
        <div data-cursor className="backButton js-backButton">
          <span className="backButton__bg"></span>
          <div className="backButton__icon__wrap">
            <i
              className="backButton__button js-top-button"
              data-feather="arrow-up"
            ></i>
          </div>
        </div>

        <Background />

        <hr className="hr-colorful" />
        <HowToStake />
        <hr className="hr-colorful" />
        <main className="bg-dark-1">
          <div className="layout-pt-sm layout-pb-sm text-center">
            <h2 className="sectionHeading__title fw-600 text-purple layout-pb-xs">
              BUY PIKA FOR STAKING OR EVOLUTION
            </h2>
            <div id="rubic-widget-root"></div>
          </div>

          <div className="layout-pt-sm layout-pb-sm text-center">
            <h2 className="sectionHeading__title fw-600 text-purple layout-pb-xs">
              TRADE USING UNISWAP
            </h2>
            <iframe
              src="https://app.uniswap.org/#/swap?use=V2&outputCurrency=0x60F5672A271C7E39E787427A18353ba59A4A3578"
              height="660px"
              width="100%"
              style={{
                border: "0",
                margin: "0 auto",
                display: "block",
                borderRadius: "10px",
                maxWidth: "600px",
                minWidth: "300px",
              }}
              id="myId"
            ></iframe>
          </div>

          <hr className="hr-colorful" />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
