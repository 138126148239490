import React from "react";

import { Container } from "./styles";

function HowToStake() {
  return (
    <Container>
      <main className="bg-dark-1">
        <div className="layout-pt-sm layout-pb-sm text-center">
          <h2 className="sectionHeading__title fw-600 text-purple layout-pb-xs pb-0">
            HOW TO STAKE?
          </h2>
          <div id="rubic-widget-root"></div>
        </div>

        <div className="layout-pt-sm layout-pb-sm text-center pt-0">
          <iframe
            src="https://www.youtube.com/embed/_lTZFiAkGds"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <hr className="hr-colorful" />
      </main>
    </Container>
  );
}

export default HowToStake;
