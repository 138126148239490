import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import RobotImage from "../../assets/robot_oops.png";
import { Context } from "../../context";
import { Metra__factory, Pika__factory } from "../../types/typechain";
import { chainId, IEnvironment } from "../../types/types";
import { getEnvironment } from "../../utils/environments";
import { Container } from "./styles";

interface IBalances {
  pika: ethers.BigNumber;
}

export function MetraEvolutionControls() {
  const [balances, setBalances] = useState<IBalances>();
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [environmentError, setEnvironmentError] = useState(false);
  const [amount, setAmount] = useState("");
  const context = useContext(Context);

  const handleBalances = async (_environment?: IEnvironment) => {
    const signer = context.provider!.getSigner();
    const address = await signer.getAddress();
    const currentEnvironment = environment ?? _environment!;
    const pika = Pika__factory?.connect(currentEnvironment.PIKA, context.provider!);

    setBalances({
      pika: await pika.balanceOf(address),
    });
  };

  useEffect(() => {
    if (context.provider !== null) {
      context.provider.on("network", async (newNetwork: { chainId: chainId }, oldNetwork: { chainId: chainId }) => {
        const _environment = getEnvironment(newNetwork.chainId);
        console.log(_environment, environment, balances);
        if (!_environment) {
          setEnvironmentError(true);
        } else {
          setEnvironment(_environment);
          handleBalances(_environment);
        }
      });
    }
  }, [context.provider, environment]);

  const onMaxClick = () => {
    setAmount(ethers.utils.formatEther(balances!.pika));
  };

  const evolve = async () => {
    const signer = context.provider!.getSigner();
    const address = await signer.getAddress();
    const pika = Pika__factory?.connect(environment!.PIKA, context.provider!);
    const metra = Metra__factory.connect(environment!.METRA, signer);
    const amountWei = ethers.utils.parseEther(amount);
    const approvedAmount = await pika.allowance(address, metra.address);
    console.log(ethers.utils.formatEther(approvedAmount));
    let tx: ethers.ContractTransaction;
    if (amountWei.gt(approvedAmount)) {
      // not approved enough
      const domain = {
        name: environment!.PIKA_NAME,
        version: "1",
        chainId: context.provider?.network.chainId.toString(),
        verifyingContract: pika.address,
      };
      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      const deadline = Math.floor(Date.now() / 1000) + 604800;
      const values = {
        owner: address,
        spender: metra.address,
        value: ethers.constants.MaxUint256,
        nonce: await pika.nonces(address),
        deadline,
      };
      const signature = await signer._signTypedData(domain, types, values);

      tx = await metra.evolveWithPermit(amountWei, deadline, signature);
    } else {
      // already approved
      tx = await metra.evolve(amountWei);
    }

    console.log(tx.hash);
    await tx.wait();
    handleBalances();
  };

  const getAmountMetra = () => {
    try {
      const amountWei = ethers.utils.parseEther(amount).div(10000);
      return ethers.utils.formatEther(amountWei);
    } catch {
      return "0";
    }
  };

  return (
    <>
      <Container>
        <div className="content">
          {environmentError ? (
            <div className="oops-message-div">
              <p className="page-title">Looks like you are using a wrong network</p>
              <img src={RobotImage} />
              <p className="staking-title">Please change the network to Ethereum Mainnet to use our platform.</p>
            </div>
          ) : (
            <div className="content-body">
              <div className="staking-title box-title">
                <Link to="/evolution">
                  <Button variant="outline-light">Go Back</Button>
                </Link>
                <p className="page-title">Evolve Pika to Metra</p>
              </div>

              <Form className="form">
                <div className="box-title" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="normal-title">
                    <Form.Label htmlFor="basic-url" className="label-custom">
                      <span>Available Tokens:</span>
                      <span id="available-pika">{balances && ethers.utils.formatEther(balances!.pika)} PIKA</span>
                    </Form.Label>
                  </div>
                  <div className="normal-title">
                    <Form.Label htmlFor="basic-url">Amount to Evolve</Form.Label>
                  </div>
                </div>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon3">PIKA</InputGroup.Text>
                  <FormControl
                    id="pika-amount"
                    aria-describedby="basic-addon3"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <Button variant="outline-secondary" id="button-addon2" className="max-button" onClick={onMaxClick}>
                    MAX
                  </Button>
                </InputGroup>
                <div className="box-title" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="normal-title">
                    <Form.Label htmlFor="basic-url" className="label-custom">
                      <span>Amount of METRA to receive:</span>
                      <span id="available-pika">{getAmountMetra()} METRA</span>
                    </Form.Label>
                  </div>
                </div>
                <div className="d-grid gap-2">
                  <Button variant="secondary" size="lg" onClick={() => evolve()}>
                    Evolve
                  </Button>
                </div>
              </Form>

              <div>
                <p style={{ fontSize: 0 }}>.</p>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
