import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import pikaImg from "../../assets/pika_logo-yellow.png";
import WalletConnButton from "../WalletConnButton";
import { ContainerCustom } from "./styles";
import { useContext } from "react";
import { Context } from "../../context";

export function Header() {
  const context = useContext(Context);
  return (
    <ContainerCustom>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="https://pikacrypto.com">
            <img
              src={pikaImg}
              width="80"
              height="80"
              className="d-inline-block align-top"
              alt="Pika Crypto Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="header-custom">
              <Nav.Link href="/">GameFi Play</Nav.Link>
              <Nav.Link href="https://pikacrypto.com/#thekraft">
                TheKraft
              </Nav.Link>
              <Nav.Link href="https://pikacrypto.com/assets/whitepaper/whitepaper.pdf">
                Whitepaper
              </Nav.Link>
              <Nav.Link href="https://pikacrypto.com/#tokenomics">
                Tokenomics
              </Nav.Link>

              <NavDropdown title="Community" id="basic-nav-dropdown">
                <NavDropdown.Item href="https://t.me/PIKACryptoEntry">
                  Telegram
                </NavDropdown.Item>
                <NavDropdown.Item href="https://www.instagram.com/pikacrypto/">
                  Instagram
                </NavDropdown.Item>
                <NavDropdown.Item href="https://www.reddit.com/r/PikaCrypto/">
                  Reddit
                </NavDropdown.Item>
                <NavDropdown.Item href="https://www.facebook.com/PIKACrypto">
                  Facebook
                </NavDropdown.Item>
                <NavDropdown.Item href="https://twitter.com/PikaCrypto_">
                  Twitter
                </NavDropdown.Item>
                <NavDropdown.Item href="https://discord.gg/zWR7SghmDu">
                  Discord
                </NavDropdown.Item>
                <NavDropdown.Item href="https://www.youtube.com/channel/UC8_BfexXenB3XfBPOapOZDQ">
                  Youtube
                </NavDropdown.Item>
                <NavDropdown.Item href="https://www.tiktok.com/@pika.crypto?lang=en">
                  TikTok
                </NavDropdown.Item>
                <NavDropdown.Item href="https://github.com/GameFiLtd/Pika-core">
                  Github
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="https://pikacrypto.com/#useful">
                Useful Links
              </Nav.Link>
              <Nav.Link href="https://pikacrypto.com/#team">Team</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <div className="btn-connect-div" style={{ maxWidth: "100%" }}>
            {/* <Button
              id="btn-connect"
              onClick={WebConnWallet}
              className="btn-connect"
              size="sm"
            >
              Connect Wallet
            </Button> */}
            <WalletConnButton></WalletConnButton>
          </div>
        </Container>
      </Navbar>
      {/* <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <div className="price-text-div">
            <span className="price-text">
              Pika Price: US$ &nbsp;{context.pikaprice}
            </span>
          </div>
        </Container>
      </Navbar> */}
    </ContainerCustom>
  );
}
