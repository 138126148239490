import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import { Context } from "../../context";
import { Pika__factory, Staking__factory } from "../../types/typechain";
import { chainId, IEnvironment } from "../../types/types";
import { getEnvironment } from "../../utils/environments";
import { Container } from "./styles";
import RobotImage from "../../assets/robot_oops.png";

interface IBalances {
  pika: ethers.BigNumber;
  stakedPika: ethers.BigNumber;
  totalPika: ethers.BigNumber;
  ethRewards: ethers.BigNumber;
}

// Todos renan
// make title beautiful if no wallet is connected => Done
// add metamask? wallet connect has no metamask option or maybe add metamask to wallet connect
// staking field input should be passed to stake function
// max button should put balance into input
// collect eth rewards has no input parameter and collects all rewards automatically => show how many rewards can be claimed and remove input field
// in line 195 I put an example on how to get all the balances you need

export function PikaStakingControls() {
  const context = useContext(Context);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [environmentError, setEnvironmentError] = useState(false);
  const [balances, setBalances] = useState<IBalances>();
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [unStakeAmount, setUnstakeAmount] = useState<string>("");
  const [address, setAddress] = React.useState("");

  const handleBalances = async (_environment?: IEnvironment) => {
    const signer = context.provider!.getSigner();
    const address = await signer.getAddress();
    const currentEnvironment = environment ?? _environment!;
    const pika = Pika__factory?.connect(
      currentEnvironment.PIKA,
      context.provider!
    );
    const staking = Staking__factory.connect(
      currentEnvironment.STAKING,
      context.provider!
    );

    setBalances({
      pika: await pika.balanceOf(address),
      stakedPika: await staking.balanceOf(address),
      totalPika: await staking.tokenBalance(address),
      ethRewards: await staking.claimableRewardsOf(address),
    });
  };
  useEffect(() => {
    if (context.provider === null) {
      setAddress("");
    }
    const signer = context.provider?.getSigner();
    signer?.getAddress().then((address) => setAddress(address));
  }, [context]);

  useEffect(() => {
    if (context.provider !== null) {
      context.provider.on(
        "network",
        async (
          newNetwork: { chainId: chainId },
          oldNetwork: { chainId: chainId }
        ) => {
          const _environment = getEnvironment(newNetwork.chainId);
          console.log(_environment, environment);
          if (!_environment) {
            setEnvironmentError(true);
          } else {
            setEnvironment(_environment);
            handleBalances(_environment);
          }
        }
      );
    }
  }, [context.provider, environment]);

  useEffect(() => {
    console.log(address, balances);
  }, [balances, address]);

  const stake = async (amount: string) => {
    const signer = context.provider!.getSigner();
    const staking = Staking__factory.connect(environment!.STAKING, signer);
    const pika = Pika__factory.connect(environment!.PIKA, signer);
    const address = await signer.getAddress();
    const amountWei = ethers.utils.parseEther(amount);

    const approvedAmount = await pika.allowance(address, environment!.STAKING);
    let tx: ethers.ContractTransaction;
    if (amountWei.gt(approvedAmount)) {
      // not approved enough
      const domain = {
        name: environment!.PIKA_NAME,
        version: "1",
        chainId: context.provider?.network.chainId.toString(),
        verifyingContract: pika.address,
      };
      const types = {
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      };

      const deadline = Math.floor(Date.now() / 1000) + 604800;
      const values = {
        owner: address,
        spender: staking.address,
        value: ethers.constants.MaxUint256,
        nonce: await pika.nonces(address),
        deadline,
      };
      const signature = await signer._signTypedData(domain, types, values);

      tx = await staking.depositWithPermit(amountWei, deadline, signature);
    } else {
      // already approved
      tx = await staking.deposit(amountWei);
    }

    console.log(tx.hash);
    await tx.wait();
    handleBalances();
  };

  const unstake = async (amount: string) => {
    const amountWei = ethers.utils.parseEther(amount);
    const signer = context.provider!.getSigner();
    const staking = Staking__factory.connect(environment!.STAKING, signer);
    const withdrawnStakedTokens = amountWei
      .mul(balances!.stakedPika)
      .div(balances!.totalPika);
    const tx = await staking.withdraw(withdrawnStakedTokens, true);
    console.log(tx.hash);
    await tx.wait();
    handleBalances();
  };

  const claimEthRewards = async () => {
    const signer = context.provider!.getSigner();
    const staking = Staking__factory.connect(environment!.STAKING, signer);
    const tx = await staking.claimRewards(await signer.getAddress());
    console.log(tx.hash);
    await tx.wait();
    handleBalances();
  };
  // window.location.reload();
  return (
    <>
      <Container>
        <div className="content">
          <div className="content-body">
            <div className="staking-title title-stake">
              <Link to="/staking">
                <Button variant="outline-light">Go Back</Button>
              </Link>
              <p className="page-title">
                {environmentError
                  ? "Looks like you are using a wrong network"
                  : address !== null
                  ? "Pika Staking"
                  : "PLEASE CONNECT WALLET"}
              </p>
            </div>
            {/* {false ? ( */}
            {environmentError ? (
              <div className="oops-message-div">
                <img src={RobotImage} />
                <p className="staking-title">
                  Please change the network to Ethereum Mainnet to use our
                  platform.
                </p>
              </div>
            ) : address === null || balances === undefined ? (
              <div className="oops-message-div">
                <img src={RobotImage} />
                <p className="staking-title">
                  Please use the Connect button to play the game.
                </p>
              </div>
            ) : (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>JOIN STAKING POOL</Accordion.Header>
                  <Accordion.Body>
                    <Form className="form">
                      {balances?.pika && (
                        <div className="reverse-title">
                          <Form.Label
                            htmlFor="basic-url"
                            className="label-custom"
                          >
                            Available:
                            <span id="available-pika-stake">
                              {ethers.utils.formatEther(balances!.pika)} PIKA
                            </span>
                          </Form.Label>
                        </div>
                      )}
                      <div className="title-stake">
                        <div className="normal-title">
                          <Form.Label htmlFor="basic-url">
                            Amount to Stake
                          </Form.Label>
                        </div>
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          PIKA
                        </InputGroup.Text>
                        <FormControl
                          id="stake-pika-amount"
                          aria-describedby="basic-addon3"
                          onChange={(event) => {
                            setStakeAmount(event.target.value);
                          }}
                          value={stakeAmount}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          className="max-button"
                          onClick={() => {
                            setStakeAmount(
                              ethers.utils.formatEther(balances!.pika)
                            );
                          }}
                        >
                          MAX
                        </Button>
                      </InputGroup>
                      <div className="d-grid gap-2">
                        <Button
                          variant="secondary"
                          size="lg"
                          // disabled
                          onClick={async () => {
                            if (stakeAmount?.length === 0) {
                              return alert(
                                "PLEASE INSERT THE DESIRED AMOUNT TO STAKE"
                              );
                            }
                            stake(stakeAmount);
                          }}
                        >
                          Stake Now
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>WITHDRAW STAKED TOKENS</Accordion.Header>
                  <Accordion.Body>
                    <Form className="form">
                      {balances?.pika && (
                        <div className="reverse-title">
                          <Form.Label
                            htmlFor="basic-url"
                            className="label-custom"
                          >
                            Accumulated Tokens:
                            <span id="available-pika-unstake">
                              {ethers.utils.formatEther(balances!.totalPika)}{" "}
                              PIKA
                            </span>
                          </Form.Label>
                        </div>
                      )}
                      <div className="title-stake">
                        <div className="normal-title">
                          <Form.Label htmlFor="basic-url">
                            Amount to Unstake
                          </Form.Label>
                        </div>
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">
                          PIKA
                        </InputGroup.Text>
                        <FormControl
                          id="unstake-pika-amount"
                          aria-describedby="basic-addon3"
                          onChange={(event) => {
                            setUnstakeAmount(event.target.value);
                          }}
                          value={unStakeAmount}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          className="max-button"
                          onClick={() => {
                            setUnstakeAmount(
                              ethers.utils.formatEther(balances!.totalPika)
                            );
                          }}
                        >
                          MAX
                        </Button>
                      </InputGroup>
                      <div className="d-grid gap-2">
                        <Button
                          variant="primary"
                          size="lg"
                          onClick={async () => {
                            if (unStakeAmount?.length === 0) {
                              return alert(
                                "PLEASE INSERT THE DESIRED AMOUNT TO UNSTAKE"
                              );
                            }
                            unstake(unStakeAmount);
                          }}
                        >
                          Unstake Now
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>COLLECT ETH REWARDS</Accordion.Header>
                  <Accordion.Body>
                    <Form className="form">
                      {balances?.ethRewards && (
                        <div className="reverse-title">
                          <Form.Label
                            htmlFor="basic-url"
                            className="label-custom"
                          >
                            Available:
                            <span id="available-eth-rewards">
                              {ethers.utils.formatEther(balances!.ethRewards)}{" "}
                              ETH
                            </span>
                          </Form.Label>
                        </div>
                      )}
                      <div className="d-grid gap-2">
                        <Button
                          variant="tertiary"
                          size="lg"
                          onClick={async () => {
                            claimEthRewards();
                          }}
                        >
                          Collect ETH Rewards
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
