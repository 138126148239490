import { Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import { Staking } from "../pages/Staking";
import { PikaStk } from "../pages/PikaStk";
import { PikaLiq } from "../pages/PikaLiq";
import { Evolution } from "../pages/Evolution";
import { MetraEvl } from "../pages/MetraEvl";
import { MetraLiq } from "../pages/MetraLiq";
import { MetraStk } from "../pages/MetraStk";
import { LiquidityProvision } from "../pages/LiquidityProvision";

export default function MainRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/staking" exact component={Staking} />
      <Route path="/staking/pika" exact component={PikaStk} />
      <Route path="/staking/metra" exact component={MetraStk} />
      <Route path="/evolution" exact component={Evolution} />
      <Route path="/evolution/metra" exact component={MetraEvl} />
      <Route path="/liquidity" exact component={LiquidityProvision} />
      <Route path="/liquidity/pika" exact component={PikaLiq} />
      <Route path="/liquidity/metra" exact component={MetraLiq} />
    </Switch>
  );
}
