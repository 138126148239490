import { Header } from "../Header";
import { Container } from "./styles";
import backgroundImg from "../../assets/universalbg.jpg";
import { SectionImg } from "../SectionImg";

export function Background() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <SectionImg />
    </Container>
  );
}
