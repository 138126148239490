import backgroundImg from "../../assets/universalbg.jpg";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { StakeSelection } from "../../components/StakeSelection";
import { Container } from "./styles";

export function Staking() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <StakeSelection />
      <Footer />
    </Container>
  );
}
