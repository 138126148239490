import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import { Link } from "react-router-dom";
import StakingImg from "../../assets/pika_logo-yellow.png";
import BlockedImage1 from "../../assets/metra-logo-new.png";
import BlockedImage2 from "../../assets/kai_logo.png";
import { Container } from "./styles";

export function StakeSelection() {
  return (
    <>
      <Container>
        <div className="content">
          <div className="">
            <div className="staking-title title-stake">
              <Link to="/">
                <Button variant="outline-light">Go Back</Button>
              </Link>
              <p className="page-title">
                Select The Token to Provide Liquidity
              </p>
            </div>

            <CardGroup style={{ justifyContent: "center", gap: 80 }}>
              <Card
                style={{
                  maxWidth: 220,
                  minWidth: 220,
                  maxHeight: 220,
                  minHeight: 220,
                  margin: +window.innerWidth <= 575 ? "20px auto 70px" : "0",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  href="/liquidity/pika"
                  style={{
                    color: "white",
                    outline: "none",
                    textDecoration: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <Card.Img variant="top" src={StakingImg} />
                  <Card.Body
                    style={{
                      position: "absolute",
                      bottom: -45,
                      left: 0,
                      right: 0,
                    }}
                  >
                    <Card.Title>Select PIKA</Card.Title>
                  </Card.Body>
                  {/* <Card.Footer>
                    <span> APR: </span>
                    <span id="pika-apr-percentage">APR</span>
                    <span>%</span>
                  </Card.Footer> */}
                </a>
              </Card>

              
            </CardGroup>
          </div>
        </div>
      </Container>
    </>
  );
}
