import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import Provider from "./context";
import MainRoutes from "./routes/route";
import "./styles/App.css";
import { GlobalStyle } from "./styles/global";
import "./styles/vendors.css";

function App() {
  return (
    <>
      <Provider>
        <BrowserRouter>
          <MainRoutes />
        </BrowserRouter>
        <GlobalStyle />
      </Provider>
    </>
  );
}

export default App;
