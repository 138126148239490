import "../../styles/App.css";
import "../../styles/vendors.css";
import { Container } from "./styles";

export function Footer() {
  return (
    <Container>
      <div className="border-top-light">
        <footer
          className="footer -type-1"
          style={{ backgroundColor: "transparent" }}
        >
          <div className="container">
            <div className="footer__bottom -light">
              <div className="row">
                <div className="col">
                  <div className="footer__copyright">
                    <p className="text-light text-center">
                      @2021, GameFi AG. All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Container>
  );
}
