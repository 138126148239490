import { ethers } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import TimeAgo from "react-timeago";
import RobotImage from "../../assets/robot_oops.png";
import { Context } from "../../context";
import { Liquidity__factory, Metra__factory } from "../../types/typechain";
import { chainId, IEnvironment } from "../../types/types";
import { getEnvironment } from "../../utils/environments";
import { Container } from "./styles";

interface IBalances {
  liquidity: ethers.BigNumber;
  eth: ethers.BigNumber;
}

interface ILockedPeriod {
  periodId: number;
  locked: boolean;
  endTimeStamp: number;
  balance: ethers.BigNumber;
  withdrawableBalance: ethers.BigNumber;
  withdrawnBalance: ethers.BigNumber;
}

// Todos renan
// make title beautiful if no wallet is connected => Done
// add metamask? wallet connect has no metamask option or maybe add metamask to wallet connect
// staking field input should be passed to stake function
// max button should put balance into input
// collect eth rewards has no input parameter and collects all rewards automatically => show how many rewards can be claimed and remove input field
// in line 195 I put an example on how to get all the balances you need

export function MetraLiquidityControls() {
  const context = useContext(Context);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [environmentError, setEnvironmentError] = useState(false);
  const [balances, setBalances] = useState<IBalances>();
  const [lock, setLock] = useState<boolean | undefined>(undefined);
  const [provisionAmount, setProvisionAmount] = useState<string>("");
  const [periods, setPeriods] = useState<ILockedPeriod[]>([]);
  // const [unStakeAmount, setUnstakeAmount] = useState<string>("");
  const [address, setAddress] = React.useState("");
  // const [balancesETH, setBalancesETH] = React.useState("");

  const handleBalances = async (_environment?: IEnvironment) => {
    const signer = context.provider!.getSigner();
    const address = await signer.getAddress();
    const currentEnvironment = environment ?? _environment!;
    const metra = Metra__factory.connect(currentEnvironment.METRA, context.provider!);
    const liquidity = Liquidity__factory.connect(currentEnvironment.LIQUIDITY_METRA, context.provider!);
    const network = "mainnet"; // use rinkeby testnet
    const provider = ethers.getDefaultProvider(network);
    setBalances({
      liquidity: await metra.balanceOf(currentEnvironment.LIQUIDITY_METRA),
      eth: await provider.getBalance(address),
    });
    const nonce = await liquidity.nonces(address);
    const _periods: ILockedPeriod[] = [];
    const lockedPeriod = await liquidity.lockPeriod();
    const vestingPeriod = await liquidity.vestingPeriod();
    for (let i = 0; i < nonce.toNumber(); i++) {
      const withdrawableBalance = await liquidity.withdrawableBalance(address, i);
      const info = await liquidity.deposits(address, i);
      _periods.push({
        periodId: i,
        locked: info.locked,
        balance: info.balance,
        endTimeStamp: info.timestamp + (info.locked ? lockedPeriod : vestingPeriod).toNumber(),
        withdrawableBalance,
        withdrawnBalance: info.withdrawnBalance,
      });
    }
    console.log(_periods);
    setPeriods(_periods);
  };
  useEffect(() => {
    if (context.provider === null) {
      setAddress("");
    }
    const signer = context.provider?.getSigner();
    signer?.getAddress().then((address) => setAddress(address));
  }, [context]);

  useEffect(() => {
    if (context.provider !== null) {
      context.provider.on("network", async (newNetwork: { chainId: chainId }, oldNetwork: { chainId: chainId }) => {
        const _environment = getEnvironment(newNetwork.chainId);
        console.log(_environment, environment);
        if (!_environment) {
          setEnvironmentError(true);
        } else {
          setEnvironment(_environment);
          handleBalances(_environment);
        }
      });
    }
  }, [context.provider, environment, periods]);

  useEffect(() => {
    console.log(address, balances);
  }, [balances, address]);

  // window.location.reload();
  const provideLiquidity = async () => {
    if (lock === undefined) {
      return;
    }
    const signer = context.provider!.getSigner();
    const liquidity = Liquidity__factory.connect(environment!.LIQUIDITY_METRA, signer);
    await liquidity.provideLiquidity(lock, { value: ethers.utils.parseEther(provisionAmount) });
  };

  const removeLiquidity = async (i: number) => {
    const signer = context.provider!.getSigner();
    const liquidity = Liquidity__factory.connect(environment!.LIQUIDITY_METRA, signer);
    await liquidity.withdraw(i);
  };

  return (
    <>
      <Container>
        <div className="content">
          <div className="content-body">
            <div className="staking-title title-stake">
              <Link to="/liquidity">
                <Button variant="outline-light">Go Back</Button>
              </Link>
              <p className="page-title">
                {environmentError
                  ? "Looks like you are using a wrong network"
                  : address !== null
                  ? "Liquidity Provision - METRA"
                  : "PLEASE CONNECT WALLET"}
              </p>
            </div>
            {/* {false ? ( */}
            {environmentError ? (
              <div className="oops-message-div">
                <img src={RobotImage} />
                <p className="staking-title">Please change the network to Ethereum Mainnet to use our platform.</p>
              </div>
            ) : address === null || balances === undefined ? (
              <div className="oops-message-div">
                <img src={RobotImage} />
                <p className="staking-title">Please use the Connect button to play the game.</p>
              </div>
            ) : (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>ADD LIQUIDITY TO METRA</Accordion.Header>
                  <Accordion.Body>
                    <Form className="form">
                      <div className="reverse-title">
                        <Form.Label htmlFor="basic-url" className="label-custom">
                          Available METRA in the pool:
                          <span id="available-pika-stake">{ethers.utils.formatEther(balances.liquidity)} METRA</span>
                        </Form.Label>
                      </div>
                      {balances?.liquidity && (
                        <div className="reverse-title">
                          <Form.Label htmlFor="basic-url" className="label-custom">
                            Your ETH balance:
                            <span id="available-pika-stake">{ethers.utils.formatEther(balances.eth)} ETH</span>
                          </Form.Label>
                        </div>
                      )}
                      <div className="title-stake">
                        <div className="normal-title">
                          <Form.Label htmlFor="basic-url">Amount to Provide</Form.Label>
                        </div>
                      </div>
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon3">ETH</InputGroup.Text>
                        <FormControl
                          id="stake-pika-amount"
                          aria-describedby="basic-addon3"
                          onChange={(event) => {
                            setProvisionAmount(event.target.value);
                          }}
                          value={provisionAmount}
                        />

                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          className="max-button"
                          onClick={() => {
                            setProvisionAmount(ethers.utils.formatEther(balances.eth));
                          }}
                        >
                          MAX
                        </Button>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <Form.Select
                          aria-label="Provide Model Selector"
                          style={{ borderBottom: "10px" }}
                          onChange={(e) => {
                            if (e.target.value === "lock") {
                              setLock(true);
                            } else if (e.target.value === "vested") {
                              setLock(false);
                            } else {
                              setLock(undefined);
                            }
                          }}
                        >
                          <option>Please Select the type of provision</option>
                          <option value="lock">6 months lock</option>
                          <option value="vested">12 months vested</option>
                        </Form.Select>
                      </InputGroup>
                      <div className="d-grid gap-2">
                        <Button
                          // variant="secondary"
                          style={{ background: "#A941ED", border: "none" }}
                          size="lg"
                          // disabled
                          onClick={async () => {
                            if (lock === undefined) {
                              return alert("Please select type of liquidity provision");
                            }
                            provideLiquidity();
                          }}
                        >
                          Provide Liquidity
                        </Button>
                      </div>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Claim LP Tokens</Accordion.Header>
                  <Accordion.Body>
                    {/* <Form className="form" style={{ padding: 20 }}> */}
                    <table>
                      <thead>
                        <tr>
                          <th>Amount LP Tokens</th>
                          <th>Withdrawable</th>
                          <th>Fully Unlocks</th>
                          <th>Claim LP Tokens</th>
                        </tr>
                      </thead>
                      <tbody>
                        {periods.map((period: ILockedPeriod, i: number) => {
                          return (
                            <tr key={i}>
                              <td>{ethers.utils.formatEther(period.balance.sub(period.withdrawnBalance))}</td>
                              <td>{ethers.utils.formatEther(period.withdrawableBalance)}</td>
                              <td>
                                <TimeAgo date={period.endTimeStamp * 1000} />
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger"
                                  style={{ borderRadius: 30, padding: "5px 40px" }}
                                  disabled={period.withdrawableBalance.eq(0)}
                                  onClick={() => {
                                    removeLiquidity(i);
                                  }}
                                >
                                  Claim
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* </Form> */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}
