import backgroundImg from "../../assets/universalbg.jpg";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { PikaEvolutionControls } from "../../components/PikaEvolutionControls";
import { Container } from "./styles";

export function PikaLiq() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <PikaEvolutionControls />
      <Footer />
    </Container>
  );
}
