import React, {
  useState,
  useEffect,
  createContext,
  MouseEventHandler,
} from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import Fortmatic from "fortmatic";
import { ethers } from "ethers";
import { WebConnWallet } from "../utils";
import axios from "axios";

export interface IContext {
  provider: ethers.providers.Web3Provider | null;
  setProvider: React.Dispatch<
    React.SetStateAction<ethers.providers.Web3Provider | null>
  >;
  pikaprice: string;
  setpikaPrice: React.Dispatch<React.SetStateAction<string>>;
}

export const Context = createContext<IContext>({
  provider: null,
  setProvider: () => {},
  pikaprice: "",
  setpikaPrice: () => {},
});

export async function WebConnect() {
  // const userAddress = await localStorage.getItem("@USER_ADDRESS");
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: process.env.REACT_APP_INFURA, // required
      },
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: "pk_live_07F8A7873764B13C", // required
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  const provider = await web3Modal.connect();

  return new ethers.providers.Web3Provider(provider);
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const [userAddressToken, setUserAddressToken] = useState("");
  const [pikaprice, setpikaPrice] = useState("");
  const [provider, setProvider] =
    useState<ethers.providers.Web3Provider | null>(null);

  useEffect(() => {
    (async () => {
      const WEB3_CONNECT_CACHED_PROVIDER = await localStorage.getItem(
        "WEB3_CONNECT_CACHED_PROVIDER"
      );

      if (WEB3_CONNECT_CACHED_PROVIDER == '"injected"') {
        // alert("ok");
        setProvider(await WebConnect());
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        "https://api.nomics.com/v1/prices?key=8ded12cf874869181898c28c9b20368ed72f63d4"
      );
      setpikaPrice(
        data.filter((item: any) => {
          return item.currency === "PIKA2";
        })[0]?.price
      );
    })();
  }, []);

  return (
    <Context.Provider
      value={{
        provider,
        setProvider,
        pikaprice,
        setpikaPrice,
      }}
    >
      {children}
    </Context.Provider>
  );
}
