import { chainId, IEnvironment } from "../types/types";
import environments from "../configs/environments.json";

// modev to configs/enviromnent.json
// export const environments: { [key in chainId]: IEnvironment } = {
//   4: {
//     PIKA: "0x2A335a50Be85975FAAfAdb62D0384878b72C1F65",
//     PIKA_NAME: "Test Token",
//     STAKING: "0x77B847840a1666BB07497D82bDE691EB5362f7a0",
//     LIQUIDITY: "0x9f245dF0591A9e259cA182A50fC8034c4743586E",
//   },
//   1: {
//     PIKA: "0x60F5672A271C7E39E787427A18353ba59A4A3578",
//     PIKA_NAME: "PIKA",
//     STAKING: "0xd7FAac163c38cE303459089153F9C6f29b17f0BC",
//     LIQUIDITY: "0xaF5763c06Afaa136DA58e5B45F9cE9921C1fbA5b",
//   },
// };

export function getEnvironment(chainId: chainId): IEnvironment {
  console.log(environments, chainId);
  // if (!environments.hasOwnProperty(chainId.toString())) {
  //   throw new Error("Network not supported");
  // }
  return environments[chainId];
}
