import styled from "styled-components";

export const Container = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap');
  width: 100%;
  height: 100%;
  
border: red 1 ;

.label-custom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-title{
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    height: 35px;
    text-align: center;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    color: gold;
    
  }

  .oops-message-div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


.content {
  
  width: 100%;
  min-height: calc(100vh - (118px + 168px));
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;
 

  .pika {
   display: flex;
   justify-content: center;
   gap: 10px;
   }

   .form{
    padding: 5rem;
    border: 1px solid;
    border-radius: 10px;
    background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
   }

   .form-label{
     color: white;
   }

   .box-title{
     display: flex;
     justify-content: space-between;
   }

   .max-button{
     cursor: pointer;
     background-color: white;
     border-color: transparent;
   }

   .accordion-item{
     background-color: transparent;
   }

   @media (min-width: 280px) {
    .accordion{
     width: 95vw;
     }
     .form{
      padding: 1rem;
      border: 1px solid;
      border-radius: 10px;
      background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
     }
   }

   @media (min-width: 320px) {
    .accordion{
     width: 95vw;
     }
     .form{
      padding: 1rem;
      border: 1px solid;
      border-radius: 10px;
      background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
     }
   }

   @media (min-width: 375px) {
    .accordion{
     width: 95vw;
     }
     .form{
      padding: 1rem;
      border: 1px solid;
      border-radius: 10px;
      background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
     }
   }

   @media (min-width: 425px) {
    .accordion{
     width: 90vw;
     }
     .form{
      padding: 1rem;
      border: 1px solid;
      border-radius: 10px;
      background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
     }
   }


   @media (min-width: 768px) {
    .accordion{
     width: 80vw;
     }
     .content-body{
       width: 55vh;
       
     }
   }
   @media(max-width:1000px){
    .content-body{
      display: flex;
      flex-direction:column;
      justify-content:space-between;
    }
   }

   @media (min-width: 1024px) {
   .accordion{
    width: 45vw;
    }
  }

  

    .accordion-button{
      background-color: transparent;
      color: white;
      border: 1px solid;
      border-radius: 15px;
      width: 100%;
  }

  .btn-primary{
    background-color: #A941ED;
    border-color: #A941ED;
    border-radius: 15px;
    color: white;
  }

  .btn-secondary{
    background-color: #FFC007;
    border-color: #FFC007;
    border-radius: 15px;
    color: white;
  }

  .btn-tertiary{
    background-color: #5ca6ce;
    border-color: #5ca6ce;
    border-radius: 15px;
    color: white;
  }

  .form-control{
    max-width: 85%;
    border: 0;
  }

  .input-group-text{
    border: 0;
  }


  .staking-title{
    margin-top: 3rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .page-title{
    margin-left: auto;
    margin-right: auto;
  }

  @media(max-width:760px){
    .staking-title{
      display: flex;
      flex-direction:column;
      gap:30px;

      a{
        align-self:flex-start;
      }
    }
  }

  
  
  @keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
 } 

    

    .div-columimg {
      animation: float 6s ease-in-out infinite;
      height: 20rem;
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(255, 255, 255, 0.2);
      background: linear-gradient( to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) );
      border-radius: 10px;
      overflow: hidden;
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(5px);
      cursor: pointer;
    }

    img {
      width: 70%;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)
      }

    @media (max-width: 920px) {
      .div-columimg {
        animation: float 6s ease-in-out infinite;
        height: 20rem;
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(255, 255, 255, 0.2);
        background: linear-gradient( to right bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) );
        border-radius: 10px;
        overflow: hidden;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(5px);
      }
      img {
        width: 65%;
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)
        }


    }

    

  .div-columimg img:after{
  content:"";
  position:absolute;
  width:100%;
  height:100%;
  background-color:#0CF;
  opacity:0;
  border-radius:5px;
  box-shadow:0 5px 15px 0 rgba(0,0,0,0.3);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index -1;
}

.div-columimg img:hover{
  transform:scale(1.25,1.25);
}

.div-columimg img:hover::after{
  opacity:1;
}


  }

  @media (max-width: 920px) {
    .pika{ 
      display: flex;
      width: 100%;
      margin: 0rem;
      flex-direction: column;
      align-items: center;
     
    }
  
    .pika-container{
      width: 100%;
      border: none;
      height:10rem;
      animation: none;
      background: none;
    }
    .colum-img {
      width: 100%;
      gap: 1rem;
      .div-columimg{
     width: 100%;
     height: 20rem;
     border: none;
     animation: none;
     background: none;
    }
    } 
`;
