import backgroundImg from "../../assets/universalbg.jpg";
import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { MetraStakingControls } from "../../components/MetraStakingControls";
import { Container } from "./styles";

export function MetraStk() {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImg})` }}>
      <Header />
      <MetraStakingControls />
      <Footer />
    </Container>
  );
}
