import styled from "styled-components";

export const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap");
  width: 100%;
  height: 100%;
  overflow: auto;
  border: red 1;

  .options-column {
    display: flex;
    flex-direction: column !important;
  }

  .row-data {
    align-items: center;

    > div,
    .card {
      height: 100% !important;
    }
  }

  .grid {
    display: grid;
    width: 1120px;
    margin: -120px auto 0;
    height: 220px;
    grid-template-columns: 220px 220px 220px 220px;
    grid-template-rows: 220px;
    gap: 80px;

    .col,
    .col .card {
      /* background-color: #7b0cc6; */
      padding: 0 !important;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    a {
      color: #fff;
      height: 100%;
    }

    .linkData {
      height: 100%;
      margin: 0 7.5%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 130%;
      }

      p {
        font-size: 30px;
        text-align: center;
        margin: 0;
        padding: 0;
      }
    }
  }
  @media (max-width: 1144px) {
    &,
    .card-section {
      height: max-content !important;
      max-height: auto !important;
      width: 100vw !important;
    }
    .grid {
      height: auto;
      grid-template-columns: 220px 220px;
      grid-template-rows: 220px 220px;
      width: 520px;
      margin: 0 auto;
    }
  }
  @media (max-width: 545px) {
    &,
    .card-section {
      height: auto !important;
      max-height: auto !important;
      width: 100vw !important;
    }
    .grid {
      grid-template-columns: auto;
      grid-template-rows: 400px 400px 400px 400px;
      padding: 20px 20px 0 20px;
      width: auto;
      margin: 0 auto;
    }

    .card h1 {
      font-size: 40px;
    }
  }

  .card {
    width: 100%;
    display: flex;
    background-color: #999;
    color: #fff;
    border: 3px solid rgba(247, 247, 247, 0.3);
    border-radius: 15px;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1)
    );
  }

  .card-section {
    width: 1120px;
    min-height: 400px;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin: auto;
    height: 70vh;
    display: flex;
    align-items: center;
    /* background-color: #7b0cc6; */
  }

  .card-body {
    text-align: center;
  }

  .cards-title {
    color: #7b0cc6;
    line-height: 1.3;
    letter-spacing: 0;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2.5rem;
  }

  .card-img-top {
    width: 90%;
    place-self: center;
  }
`;
