import styled from "styled-components";

export const Container = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;700&display=swap');
  width: 100%;
  height: 100%;
  
border: red 1 ;


.content {
  
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;
 

  .pika {
   display: flex;
   justify-content: center;
   gap: 10px;
   }

   

   .title-stake{
     display: flex;
     justify-content: space-between;
   }

   .card{
     background-color: transparent !important;
     color: white;
     border: 2px solid white;
     border-radius: 20px;
     background: linear-gradient( to right bottom,rgba(255,255,255,0.4),rgba(255,255,255,0.1) );
   }


   @media (min-width: 280px) {
    .card-group{
     width: 95vw;
     gap: 15px;
     }
     
   }

   @media (min-width: 320px) {
    .card-group{
     width: 95vw;
     gap: 15px;
     }
   }

   @media (min-width: 375px) {
    .card-group{
     width: 95vw;
     gap: 15px;
     }
   }

   @media (min-width: 425px) {
    .card-group{
     width: 90vw;
     gap: 15px;
     }
   }


   @media (min-width: 768px) {
    .card-group{
     width: 80vw;
     gap: 10px;
     }
   }

   @media (min-width: 1024px) {
   .card-group{
    width: 70vw;
    gap: 15px;
    }
    .card-img-top{
      width: 70%;
      align-self: center;
    }
  }

  
  .card-body{
    text-align: center;
    padding: 0;
  }

  .card-footer{
    text-align: center;
    padding: 0;
    color: white !important;
  }

  

  .staking-title{
    margin-top: 3rem;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .page-title{
    margin-left: auto;
    margin-right: auto;
  }

  
  
  @keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
 } 

    
`;
