import React from "react";

import { Container } from "./styles";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { StakeSelection } from "../../components/StakeSelectionEvolution";
import backgroundImg from "../../assets/universalbg.jpg";

function LiquidityProvision() {
  return (
    <Container
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Header />
      <StakeSelection />
      <Footer />
    </Container>
  );
}

export { LiquidityProvision };
