import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 400px) {
    .border-top-light {
      margin-top: 5rem;
    }
  }
  @media (min-width: 401px) and (max-width: 575px) {
    .border-top-light {
      margin-top: 18rem;
    }
  }
`;
